<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    return-to-list
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Information -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="infoFormFields"
            allow-autofocus
          />
        </b-tab>
        <!-- Tab: Sections -->
        <b-tab
          v-if="entity.id > 0"
          lazy
        >
          <template #title>
            <feather-icon
              icon="FolderIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Sections') }}</span>
          </template>
          <SectionsList
            :module="config.module"
            :parent-entity.sync="entity"
          />
        </b-tab>
        <!-- Tab: Fields -->
        <b-tab
          v-if="entity.id > 0"
          lazy
        >
          <template #title>
            <feather-icon
              icon="ClipboardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Fields') }}</span>
          </template>
          <FieldsList
            :module="config.module"
            :parent-entity.sync="entity"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import { translationUtils } from '@core/mixins/ui/translations'
import storeModule from './storeModule'
import SectionsList from './SectionsList.vue'
import FieldsList from './FieldsList.vue'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    SectionsList,
    FieldsList,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        enabled: true,
      },
      entityLoaded: false,
      categoryOptions: [],
      centerOptions: [],
      managerOptions: [],
      recipientOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'questionnaire',
        endpoint: 'questionnaire',
        route: 'questionnaires',
        title: {
          single: this.$t('Questionnaire Add'),
          plural: this.$t('Questionnaires'),
        },
      }
    },
    infoFormFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$i18n.t('Title'),
          required: true,
          rules: 'min:3',
          colSize: 6,
        },
        {
          id: 'category',
          type: 'select',
          object: true,
          label: this.$i18n.t('Category'),
          required: false,
          options: this.categoryOptions,
          colSize: 6,
        },
        {
          id: 'startDate',
          type: 'datetime',
          label: this.$t('Start Date'),
          refMaxDate: 'endDate',
          required: true,
          colSize: 4,
        },
        {
          id: 'endDate',
          type: 'datetime',
          label: this.$t('End Date'),
          refMinDate: 'startDate',
          required: true,
          colSize: 4,
        },
        {
          id: 'highRiskPatientSupported',
          type: 'checkbox',
          label: this.$t('highRiskPatientSupported'),
          colSize: 2,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
          colSize: 2,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('description'),
          colSize: 12,
        },
        {
          id: 'centers',
          type: 'selectmultiple',
          label: this.$t('Centers'),
          options: this.centerOptions,
          colSize: 12,
        },
        {
          id: 'managers',
          type: 'selectmultiple',
          label: this.$t('Managers'),
          options: this.managerOptions,
          colSize: 12,
        },
        {
          id: 'recipients',
          type: 'selectmultiple',
          label: this.$t('Recipients'),
          options: this.recipientOptions,
          colSize: 12,
        },
      ]
    },
  },
  async mounted() {
    this.categoryOptions = (await this.$store.dispatch('app-common/fetchQuestionnaireCategories')).data
    this.centerOptions = (await this.$store.dispatch('app-common/fetchQuestionnaireCenters')).data
    this.managerOptions = (await this.$store.dispatch('app-common/fetchUsers', { secondarygroup: 14 })).data
    this.recipientOptions = (await this.$store.dispatch('app-common/fetchUsers', { secondarygroup: 15 })).data
  },
}
</script>
